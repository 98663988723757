import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OuxLoggerService } from '@cisco/oux-common';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, finalize, map, take, withLatestFrom } from 'rxjs/operators';
import { ChatbotMessageModel } from 'src/app/shared/models/concrete/partials/chatbot-message.model';
import { GenAIResponseModel } from 'src/app/shared/models/concrete/partials/gen-ai-response.model';
import { PageTitleModel } from 'src/app/shared/models/concrete/request/page-title.model';
import { Message } from 'src/app/shared/models/interface/partials/chatbot-message';
import { PageTitle } from 'src/app/shared/models/interface/partials/page-titles';
import { ChatbotService } from 'src/app/shared/services/chatbot.service';
import { SpeechRecognitionService } from 'src/app/shared/services/speech-recognition.service';
import { ChatbotStore } from 'src/app/shared/stores/chatbot.store';
import { DemoUserStore } from 'src/app/shared/stores/demo-user.store';
import { MetadataStore } from 'src/app/shared/stores/metadata.store';
import { UserDetailsStore } from 'src/app/shared/stores/user-details.store';

@Component({
  selector: 'chatbot-widget',
  templateUrl: './chatbot-widget.component.html',
  styleUrls: ['./chatbot-widget.component.scss'],
  host: {
    'class': 'chatbot-widget'
  }
})
export class ChatbotWidgetComponent implements OnInit, AfterViewChecked {

  
  private isDragging = false;
  private isMouseDown = false;
  private startY = 0;
  private offsetY = 0;
  private dragThreshold = 5;
  private readonly margin = 20;

  @ViewChild('messageList') private messageList: ElementRef;
  public loading$ = this.chatbotStore.loading$;
  public currentConversation$: Observable<Message[]>;
  private currentConversation: Message[];
  private isNewConversation = true;
  public isChatbotPageActive: boolean;
  public isChatOpen: boolean = false;
  public isMicOn: boolean = false;
  private pageId: number;
  private interactionId: number;
  public messages: any;
  public userInput: string = '';
  public senderMessage: string = '';
  public outerCircleSize = '60px';
  public copied = false;
  private isNearBottom = true;
  public isSearching: boolean = false;
  public showDefaultMessage: boolean = true;
   /**
 * Represents the users CEC username
 */
   public currentUser: string = null;
   /**
    * Represents the users parsed initials
    * Note: Will only show if the users image is not available
    */
   public userInitial: string = null;
   /**
    * Represents the users CEC directory image
    */
   public userAvatar: any;
   /**
  * Determines if the users initials should show in place of their image 
  */
   public setInitials: boolean = false;

  constructor(
    private router: Router,
    private speechRecognitionService: SpeechRecognitionService,
    private chatService: ChatbotService,
    private chatbotStore: ChatbotStore,
    private userDetailsStore: UserDetailsStore,
    private metadataStore: MetadataStore,
    private demoUserStore: DemoUserStore,
    private ouxLoggerSvc: OuxLoggerService,
    private eRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef

  ) { 
    this.currentConversation$ = this.chatbotStore.currentConversation$;
  }

  ngOnInit(): void {
    this.currentConversation$.subscribe(messages => {
      this.currentConversation = messages;
    });


    this.subscriptions.push(this.chatbotStore.getIsConversationActive().subscribe(value => {
      this.showDefaultMessage = !value;
    }));
    
    this.chatbotStore.chatbotActive$.subscribe(isActive => {
      this.isChatbotPageActive = isActive;
    });

    this.setCurrentUser();
  }


  ngAfterViewChecked() {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  
  scrollToBottom(): void {
    try {
      if (this.messageList && this.messageList.nativeElement) {
        this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
      }
    } catch (err) {
      this.ouxLoggerSvc.logError(`ChatbotWidgetComponent:scrollToBottom() Scroll to bottom failed: -> ${err}`);
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any): void {
    const threshold = 2; // Adjust this value based on your needs
    const position = this.messageList.nativeElement.scrollTop + this.messageList.nativeElement.clientHeight;
    const height = this.messageList.nativeElement.scrollHeight;
    this.isNearBottom = position > height - threshold;
  }


  startRecognition() {
    if (this.isMicOn) return; // Prevent starting recognition if already listening
  
    this.isMicOn = true;
  
    this.speechRecognitionService.start().subscribe({
      next: (result: { transcript: string; isFinal: boolean }) => {
        if (result.isFinal) {
          this.userInput = result.transcript;
          // Automatically stop after final result
          this.stopListening(); 
        } else {
          this.userInput = result.transcript;
        }
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.ouxLoggerSvc.logError(`ChatAreaComponent:startRecognition() Recognition error: -> ${error}`);
        this.isMicOn = false;
        this.cdr.detectChanges();
      },
      complete: () => {
        this.isMicOn = false;
        this.cdr.detectChanges();
      }
    });
  }

  stopListening() {
    this.isMicOn = !this.isMicOn;
    this.speechRecognitionService.stop();
    this.cdr.detectChanges();
  }

  toggleThumbsUp(index: number) {

    if (this.currentConversation && this.currentConversation[index]) {
      (this.currentConversation[index] as ChatbotMessageModel).thumbsUp = !(this.currentConversation[index] as ChatbotMessageModel).thumbsUp;
      this.chatService.updateFeedback(this.currentConversation[index] as ChatbotMessageModel).pipe(take(1)).subscribe();
    }
  }

  toggleThumbsDown(index: number) {
    if (this.currentConversation && this.currentConversation[index]) {
      (this.currentConversation[index] as ChatbotMessageModel).thumbsDown = !(this.currentConversation[index] as ChatbotMessageModel).thumbsDown;
      this.chatService.updateFeedback(this.currentConversation[index] as ChatbotMessageModel).pipe(take(1)).subscribe();
    }
  }


  copyToClipboard(index: number) {
    // Copy text to clipboard
    let textToCopy;
    if((this.currentConversation[index].content as GenAIResponseModel).is_answer_unknown)
      textToCopy = "Sorry, VizBot is unable to provide an answer to your query at this time."
    else
      textToCopy = (this.currentConversation[index].content as GenAIResponseModel).output_text;

    navigator.clipboard.writeText(textToCopy).then(() => {
      (this.currentConversation[index]).copiedStatus = true;
      setTimeout(() => {
        (this.currentConversation[index]).copiedStatus = false;
      }, 3000);
    }).catch(err => {
      this.ouxLoggerSvc.logError(`ChatbotWidgetComponent:copyToClipboard() Failed to copy text: -> ${err}`);
    });
  }

  

  private subscriptions: Subscription[] = [];

  

  sendMessage(message: string) {
    if (message.trim().length === 0) {
      return;
    }
    this.scrollToBottom();
    this.isSearching = true;
    this.chatbotStore.getIsConversationActive()
      .pipe(take(1))
      .subscribe(value => {
        this.isNewConversation = !value;
      });

    this.interactionId = this.chatService.popUniqueId();
    //Create an ID for the new Conversation
    if (this.isNewConversation) {
      this.pageId = this.chatService.popUniqueId();
      this.chatbotStore.setPageId(this.pageId);
      let pageTitle: PageTitle = new PageTitleModel({ page_header: 'Generating Title...', pageId: this.pageId, interaction_id: this.interactionId, creationDate: new Date() });
      this.chatbotStore.updatePageTitles(pageTitle);
    }

    const userMessage: Message = {
      pageId: this.pageId,
      interactionId: this.interactionId,
      updatedDate: new Date(),
      content: new GenAIResponseModel({ query: message }),
    };
    this.chatbotStore.addMessage(userMessage);

    this.subscriptions.push(
      this.chatService.fetchGenAIResponse(userMessage)
        .pipe(
          catchError((error) => {
            this.ouxLoggerSvc.logError(`ChatbotWidgetComponent:sendMessage() Error fetching Vizbot response: -> ${error}`);
            return of(null);
          }),
          finalize(() => {
            if (this.isNewConversation){
              this.chatService.generateTitleAndAddToHistory(userMessage);
            }
            else {
              this.chatbotStore.refreshPageTitles(this.pageId);
            }
             
            this.userInput = '';
            this.isSearching = false;
            this.scrollToBottom();
          })
        )
        .subscribe(response => {
          if (response) {
          } else {
            let errorResponse = new ChatbotMessageModel({ html:this.chatService.convertMarkdownToHtml(new GenAIResponseModel({output_text:'I\'m sorry, I didn\'t understand that. Please try again.' }))  });
            this.chatbotStore.addMessage(errorResponse );
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
    document.removeEventListener('mouseup', this.onMouseUp);
  }


  toggleChat() {
    if (!this.isDragging) {
    this.isChatOpen = !this.isChatOpen;
    this.chatService.initializeIdQueue();
    }
  }
  clearChat(){
    this.chatbotStore.clearConversationData();
  }
  openChatbotPage(){
    this.router.navigate(['/chatbot'], { queryParams: null });
    this.toggleChat();
  }


  onMouseDown(event: MouseEvent): void {
    this.isMouseDown = true;
    this.isDragging = false; // Reset dragging flag
    const button = event.target as HTMLElement;
    this.startY = event.clientY;
    this.offsetY = event.clientY - (event.target as HTMLElement).getBoundingClientRect().top;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.isMouseDown ) {
      const distanceMoved = Math.abs(event.clientY - this.startY);

      if (distanceMoved > this.dragThreshold) {
      this.isDragging = true;
      const button = document.querySelector('.eye-logo') as HTMLElement;
      const newTop = event.clientY - this.offsetY;

      // Icon can be at minimum 100px from bottom and 100px from top
      const maxY = window.innerHeight - 100;
      const minY = 100;

      // Constrain position within bounds
      const constrainedTop = Math.max(Math.min(newTop, maxY), minY);

      button.style.top = `${constrainedTop}px`;
      if(document.getElementById('container-position'))
          this.renderer?.setStyle(document.getElementById('container-position'), 'top', `calc(${constrainedTop}px - 14%)`);
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    if (this.isMouseDown) {
      if (this.isDragging) {
        event.stopPropagation(); // Prevent click event from firing
      }
      else {
      this.toggleChat(); // Toggle chat visibility
    }
      this.isMouseDown = false;
      this.isDragging = false;
      const button = document.querySelector('.eye-logo') as HTMLElement;
    }
    this.isMouseDown = false;
  }
 //Minimize chat when mouse clicked outside of chat
  @HostListener('document:mousedown', ['$event'])
  handleClick(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.isChatOpen) {
      this.toggleChat();
    }
  }
  


  private setCurrentUser(): void {
    this.currentUser = this.userDetailsStore.getUserId();
    this.setUserAvatar(this.currentUser);
  }

  private setUserAvatar(username: string, isDemoUser?: boolean): void {
    this.userInitial = username.charAt(0).toUpperCase();

    if (isDemoUser) {
      this.userAvatar = username; // refers to: /assets/images/demo-users/image_name.png
      return;
    }

    this.userAvatar = `//wwwin.cisco.com/dir/photo/std/${username}.jpg`;
    this.validateImageSrc(username);
  }

  private validateImageSrc(username: string): void {
    let img = new Image();
    img.onload = () => { };
    img.onerror = () => {
      this.setInitials = true;
    };
    img.src = `//wwwin.cisco.com/dir/photo/std/${username}.jpg`;
  }

}
